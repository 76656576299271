
import Vue from "vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import BadgesInfoModal from "@/components/Modals/BadgesInfoModal.vue";
import { mapGetters } from "vuex";
import Rating from "@/components/Ratings/Rating.vue";

export default Vue.extend({
  name: "MentorRatingsPage",
  components: {
    ProfileData,
    Rating,
    BadgesInfoModal
  },
  data() {
    return {
      ratingTabs: [
        {
          name: "Recent",
          type: "recent"
        },
        {
          name: "Mentorcasts",
          type: "mentorcast"
        },
        {
          name: "Sessions",
          type: "session"
        }
      ],
      totalRatings: 0,
      showRatingInfoModal: false,
      currentTab: "recent",
      data: [] as any,
      page: 1,
      load_more: false
    };
  },
  computed: {
    ...mapGetters({
      mentor: "mentor/getMentor",
      ratings: "mentor/getRatings"
    })
  },
  mounted(): void {
    this.$store
      .dispatch("mentor/GET_MENTOR", {
        id: this.$route.params.id
      })
      .then(() => {
        this.getRatings();
      });
  },
  methods: {
    onLoadMore() {
      this.page++;
      this.getRatings(this.page);
    },
    getRatings(page = 1) {
      this.$store
        .dispatch("mentor/GET_RATINGS", {
          id: this.$route.params.id,
          tab: this.currentTab,
          page
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
        });
    },
    onTab(tab: any) {
      this.page = 1;
      this.data = [];
      this.currentTab = tab;
      this.getRatings();
    }
  }
});
